<!--问诊单-->
<template>
	<div>
		<div class="tips flex f-ai-c">
			<span>温馨提示：急性病以当下情况为准，慢性病以近3天症状为准，请认证填写以便医生准确诊疗</span>
		</div>
		<ul class="ques wrap">
			<!-- <li class="q-item">
				<span class="topic">1、您的寒热感受如何？<i>(多选)</i></span>
				<van-checkbox-group v-model="qa.q1">
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="1" shape="square">正常</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="2" shape="square">发热</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="3" shape="square">午后或夜间发热</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="4" shape="square">怕冷</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="5" shape="square">寒热往来</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="6" shape="square">怕风</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="7" shape="square">手足心热</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="8" shape="square">胸腹热</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="9" shape="square">手脚冰凉</van-checkbox>
						</van-grid-item>
						<van-grid-item>
						</van-grid-item>
					</van-grid>
				</van-checkbox-group>
			</li>
			<li class="q-item">
				<span class="topic">2、您的出汗情况如何？<i>(多选)</i></span>
				<van-checkbox-group v-model="qa.q2">
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="1" shape="square">正常</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="2" shape="square">醒时经常汗出、活动后明显</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="3" shape="square">睡时汗出、醒则汗止</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="4" shape="square">无汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="5" shape="square">冷汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="6" shape="square">热汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="7" shape="square">头汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="8" shape="square">黄汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="9" shape="square">站汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="10" shape="square">大汗不止</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="11" shape="square">手足心出汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="12" shape="square">半身汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="13" shape="square">心胸汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
						</van-grid-item>
					</van-grid>
				</van-checkbox-group>
			</li>
			<li class="q-item">
				<span class="topic">2、您的出汗情况如何？<i>(多选)</i></span>
				<van-checkbox-group v-model="qa.q2">
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="1" shape="square">正常</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="2" shape="square">醒时经常汗出、活动后明显</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="3" shape="square">睡时汗出、醒则汗止</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="4" shape="square">无汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="5" shape="square">冷汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="6" shape="square">热汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="7" shape="square">头汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="8" shape="square">黄汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="9" shape="square">站汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="10" shape="square">大汗不止</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="11" shape="square">手足心出汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :name="12" shape="square">半身汗</van-checkbox>
						</van-grid-item>
					</van-grid>
					<van-grid :column-num="2" :border="false" :center="false">
						<van-grid-item>
							<van-checkbox :name="13" shape="square">心胸汗</van-checkbox>
						</van-grid-item>
						<van-grid-item>
						</van-grid-item>
					</van-grid>
				</van-checkbox-group>
			</li> -->
			<li class="q-item" v-for="(item, index) in questionModels" :key="index">
				<span class="topic">{{item.title}}<i>({{item.type === 's' ? '单选' : '多选'}})</i></span>
				<van-checkbox-group v-if="!item.type" v-model="qa[item.id]" @change="onChangeMultiple">
					<van-grid :column-num="2" :border="false" :center="false" v-for="(it, ind) in item.ques" :key="ind">
						<van-grid-item>
							<van-checkbox :disabled="disabled" :name="ind * 2 + 1" shape="square">{{it[0]}}</van-checkbox>
						</van-grid-item>
						<van-grid-item>
							<van-checkbox :disabled="disabled" :name="(ind + 1) * 2" v-if="it[1]" shape="square">{{it[1]}}</van-checkbox>
						</van-grid-item>
					</van-grid>
				</van-checkbox-group>
				<van-radio-group v-else v-model="qa[item.id]" @change="onChangeMultiple">
					<van-grid :column-num="2" :border="false" :center="false" v-for="(it, ind) in item.ques" :key="ind">
						<van-grid-item>
							<van-radio :disabled="disabled" :name="ind * 2 + 1" shape="square">{{it[0]}}</van-radio>
						</van-grid-item>
						<van-grid-item>
							<van-radio :disabled="disabled" :name="(ind + 1) * 2" v-if="it[1]" shape="square">{{it[1]}}</van-radio>
						</van-grid-item>
					</van-grid>
				</van-radio-group>
			</li>
		</ul>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { patientList } from '@/request/api/user';

export default {
	name: 'Cosulation',
	data () {
		return {
			checked: true,
			questionModels: {},
			patientInfo: {},
			qa: {},
			consultationType: 1
		};
	},
	created () {
		// console.log('this.patientList====', this.patientList)
		if (this.patientList && this.patientList.length) {
			this.patientInfo = this.patientList.find(p => p.id === Number(this.$route.query.patientId));
		} else {
			this.$root.replace('/')
		}
		if (this.patientInfo) {
			this.initQA();
		} else {
			this.mainGetPatients()
		}
	},
	props: {
		disabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters({
			patientList: 'getPatientList',
			stepTwoVal: 'getStepTwo'
		})
	},
	methods: {
		mainGetPatients () {
			patientList().then(data => {
				if (data && data === 'retry') {
					this.mainGetPatients();
				} else if (data) {
					this.$store.commit('setPatients', data);
					this.initQA();
				}
			}).catch(e => {
				this.$root.elMsg(e.message);
			});
		},
		commit () {
			this.$store.commit('setStep', {
				step: 'stepTwo',
				obj: { qa: this.qa, consultationType: this.consultationType }
			});
		},
		onChangeMultiple () {
			this.checked = true;
			if (Object.keys(this.qa).length !== this.questionModels.length) {
				this.checked = false;
			}
			for (const item of this.questionModels) {
				if (item.type) {
					for (const key in this.qa) {
						if (key === item.id) {
							if (!this.qa[key]) {
								this.checked = false;
							}
						}
					}
				} else {
					for (const key in this.qa) {
						if (key === item.id) {
							if (!this.qa[key].length) {
								this.checked = false;
							}
						}
					}
				}
			}
			this.$emit('notify', this.checked);
		},
		initQA () {
			// console.log('this.patientInfo======', this.patientInfo)
			if (this.patientInfo.age < 14) {
				this.questionModels = require('./child').default;
				this.consultationType = 1;
			} else if (this.patientInfo.gender === 2) {
				this.questionModels = require('./woman').default;
				this.consultationType = 3;
			} else if (this.patientInfo.gender === 1) {
				this.questionModels = require('./man').default;
				this.consultationType = 2;
			}
			for (let i = 0; i < this.questionModels.length; i++) {
				if (this.questionModels[i].type) {
					this.$set(this.qa, this.questionModels[i].id, 1);
				} else {
					this.$set(this.qa, this.questionModels[i].id, [1]);
				}
			}
			console.log('this.stepTwoVal', this.stepTwoVal)
			if (this.stepTwoVal && this.stepTwoVal.qa) {
				this.qa = this.stepTwoVal.qa;
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.tips {
		height: 62px;
		background: #FFF7EE;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FF8D16;
		padding: 12px;
		box-sizing: border-box;
		margin-top: 10px;
	}
	.ques {
		background-color: #FFFFFF;
		margin-top: 10px;
		padding-top: 25px;
		padding-left: 15px;
		font-size: 15px;
		.q-item {
			border-bottom: 1px solid #F2F2F2;
			margin-bottom: 20px;
			.topic {
				margin-left: 8px;
			}
		}
	}
	/deep/.van-grid-item__content {
		padding: 11px 0;
	}
	/deep/.van-radio__icon--checked,
	/deep/.van-checkbox__icon--checked {
		i {
			background-color: #F3F9FF;
			color: #FF6450;
		}
		& + span {
		color: #FF6450 !important;
	}
	}
</style>