/**
 * type: s  单选， 默认为多选
 */
export default [
	{
		id: 'q1',
		title: '1、孩子的精神状态怎么样？',
		type: 's', // 单选
		ques: [
			['精神好', '精神一般']
		]
	},
	{
		id: 'q2',
		title: '2、孩子的寒热感受如何？',
		ques: [
			['正常', '发热'], ['发热有汗', '发热无汗'], ['长期低热', '午后发热'], ['夜间发热', '上午发热'], ['怕冷 ', '忽冷忽热'], ['手脚冰凉']
		]
	},
	{
		id: 'q3',
		title: '3、孩子的出汗情况如何？',
		ques: [
			['正常', '不出汗（无汗症）'], ['白天或活动后出汗明显', '睡眠后出汗明显'], ['前半夜出汗', '后半夜出汗']
		]
	},
	{
		id: 'q4',
		title: '4、孩子最近是否有觉得何处疼痛？',
		ques: [
			['无疼痛', '头痛'], ['头晕', '咽痛'], ['牙痛', '胸痛'], ['腹痛', '胃痛'], ['四肢、关节痛', '肌肉痛']
		]
	},
	{
		id: 'q5',
		title: '5、孩子咳喘、咳痰情况如何',
		ques: [
			['无咳嗽喘息、无痰', '咳嗽'], ['喘息', '有痰难咳'], ['黄痰', '白痰'], ['绿痰', '痰中带血']
		]
	},
	{
		id: 'q6',
		title: '6、孩子的大便颜色如何？',
		ques: [
			['黄色', '黑色'], ['青绿色', '便血'], ['白陶土便', '脓血便']
		]
	},
	{
		id: 'q7',
		title: '7、孩子的大便次数如何？',
		ques: [
			['每天或隔天1次', '1天多次'], ['大于3天1次', '长期便秘']
		]
	},
	{
		id: 'q8',
		title: '8、孩子的小便次数及尿量如何？',
		ques: [
			['次数正常（5-8次/日）', '次数偏多（10次以上/日）'], ['次数偏少（5次以下/日）', '夜尿次数多（2~3次以上/晚）'], ['小便色清量多']
		]
	},
	{
		id: 'q9',
		title: '9、孩子的小便颜色如何？',
		ques: [
			['清', '淡黄'], ['黄', '红'], ['浑浊如米泔水', '尿中有沉淀物'], ['尿里泡沫多']
		]
	},
	{
		id: 'q10',
		title: '10、孩子饮食、饮水情况如何？',
		ques: [
			['正常', '不想吃饭'], ['进食较多', '腹胀吃不下'], ['口渴想喝水', '口渴不想喝水'], ['多饮多尿']
		]
	},
	{
		id: 'q11',
		title: '11、孩子嘴里是否有异味？',
		ques: [
			['无异味', '口臭'], ['口苦', '口粘'], ['口酸']
		]
	},
	{
		id: 'q12',
		title: '12、孩子睡眠情况如何？',
		ques: [
			['正常', '睡眠不安稳，翻来覆去'], ['喜欢趴着睡', '睡觉不安稳、肛门瘙痒'], ['睡觉不安稳、自觉害怕', '睡觉易惊醒、梦话多'], ['睡时漏睛', '睡觉磨牙'], ['睡觉流口水']
		]
	},
	{
		id: 'q13',
		title: '13、孩子舌苔情况如何？',
		ques: [
			['舌苔薄白', '舌苔薄黄'], ['舌苔白厚', '舌苔黄厚'], ['舌苔发黑', '舌苔花剥（地图舌）']
		]
	}
];